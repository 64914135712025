
export default function DatePicker(theme) {
  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            '&:hover': {
              backgroundColor: theme.palette.primary.main,
            },
          },
        }
      }
    }
  };
}