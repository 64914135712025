import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import jwt_decode from "jwt-decode";
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  user: null,
};

const handlers = {
  SET_USER: (state, action) => {
    const { user } = action.payload;
    return {
      ...state,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    user: null,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setUser = async (token) => {
    const decoded = jwt_decode(token);
    const user = {
      isAdmin: decoded.is_admin,
      username: decoded.username,
      id: decoded.user_id,
      email: decoded.email,
    }
    dispatch({
      type: 'SET_USER',
      payload: {
        user,
      },
    });
  }

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('managerAccessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          setUser(accessToken);
        } else {
          dispatch({
            type: 'SET_USER',
            payload: {
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'SET_USER',
          payload: {
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/api/accounts/login/', {
      email,
      password,
    });
    const { access, refresh } = response.data;
    setSession(access, refresh);
    setUser(access);
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
